@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* Color palette Changes */

.bg-purple-100 {
  background: #15a4e6;
  opacity: 0.1;
}

.bg-purple-200 {
  background: #15a4e6;
  opacity: 0.2;
}

.bg-purple-300 {
  background: #15a4e6;
  opacity: 0.3;
}

.bg-purple-400 {
  background: #15a4e6;
  opacity: 0.4;
}

.bg-purple-500 {
  background: #15a4e6;
  opacity: 0.5;
}

.bg-purple-600 {
  background: #15a4e6 !important;
  opacity: 0.6;
}

.bg-purple-700 {
  background: #15a4e6;
  opacity: 0.7;
}

.bg-purple-800 {
  background: #15a4e6;
  opacity: 0.8;
}

.bg-purple-900 {
  background: #15a4e6;
  opacity: 0.9;
}

.hover\:bg-purple-100:hover {
  background-color: #15a4e6 !important;
  opacity: 0.1;
}

.hover\:bg-purple-200:hover {
  background-color: #15a4e6 !important;
  opacity: 0.2;
}

.hover\:bg-purple-300:hover {
  background-color: #15a4e6 !important;
  opacity: 0.3;
}

.hover\:bg-purple-400:hover {
  background-color: #15a4e6 !important;
  opacity: 0.4;
}

.hover\:bg-purple-500:hover {
  background-color: #15a4e6 !important;
  opacity: 0.5;
}

.hover\:bg-purple-600:hover {
  background-color: #15a4e6 !important;
  opacity: 0.6;
}

.hover\:bg-purple-700:hover {
  background-color: #15a4e6 !important;
  opacity: 0.7;
}

.hover\:bg-purple-800:hover {
  background-color: #15a4e6 !important;
  opacity: 0.8;
}

.hover\:bg-purple-900:hover {
  background-color: #15a4e6 !important;
  opacity: 0.9;
}

button.bg-purple-600{
  background: #15a4e6;
  opacity: 1;
}
/* .bg-violet-500\/50 {npm
  background: #15a4e6;
  opacity: 0.5;
} */

.focus\:ring-purple-500:focus {
  border-color: #15a4e6 !important;
}
.focus\:ring-purple-500:focus{
  --tw-ring-color: (#15a4e6 / var(--tw-ring-opacity)) !important;
}

.text-purple-100,
.text-purple-200,
.text-purple-300,
.text-purple-400,
.text-purple-500,
.text-purple-600,
.text-purple-700,
.text-purple-800,
.text-purple-900 {
  color: #15a4e6;
  /* opacity: 0.1; */
}


.hover\:text-purple-100:hover {
  color: #15a4e6 !important;
  opacity: 0.1;
}

.hover\:text-purple-200:hover {
  color: #15a4e6 !important;
  opacity: 0.2;
}

.hover\:text-purple-300:hover {
  color: #15a4e6 !important;
  opacity: 0.3;
}

.hover\:text-purple-400:hover {
  color: #15a4e6 !important;
  opacity: 0.4;
}

.hover\:text-purple-500:hover {
  color: #15a4e6 !important;
  opacity: 0.5;
}

.hover\:text-purple-600:hover {
  color: #15a4e6 !important;
  opacity: 0.6;
}

.hover\:text-purple-700:hover {
  color: #15a4e6 !important;
  opacity: 0.7;
}

.hover\:text-purple-800:hover {
  color: #15a4e6 !important;
  opacity: 0.8;
}

.hover\:text-purple-900:hover {
  color: #15a4e6 !important;
  opacity: 0.9;
}




/* end */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.menu-items.active {
  /* background: #7c3aed; */
  background: #15a4e6;
  color: #fff;
}

.react-calendar {
  border: 0px solid !important;
}

.react-calendar__tile--active {
  background: #15a4e6 !important;
}


/* Add the following CSS to your global styles or main CSS file */

/* Thin scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  /* background-color: #9588ff; */
  background-color: #15a4e6;
  /* Light purple color */
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  /* Background color */
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #9588ff #f2f2f2;
}

*::-moz-scrollbar-thumb {
  background-color: #9588ff;
  /* Light purple color */
  border-radius: 4px;
}

*::-moz-scrollbar-track {
  background-color: #f2f2f2;
  /* Background color */
}

.react-calendar {
  width: 100% !important;
}

@media (max-width: 1021px) {
  .custom_bredcrumb {
    visibility: visible !important;
    display: flex !important;
  }
}

@media (prefers-color-scheme: dark) {

  .dark\:text-white {
    color: #000000 !important;
  }

  .dark\:bg-gray-700 {
    background-color: #fff !important
  }

  .dark\:text-zinc-100 {
    color: rgb(31 41 55 / var(--tw-text-opacity)) !important;
  }

  .dark\:text-gray-100 {
    color: rgb(107 114 128 / var(--tw-text-opacity)) !important;
  }

  .dark\:text-white\/70 {
    color: rgb(107 114 128/var(--tw-text-opacity)) !important;
  }
}

.sticky--remove table thead {
  position: unset;
}

.icon-trash {
  width: 30px;
  height: 30px;
  position: relative;
  overflow: hidden;
  margin: auto;
}

.icon-trash .trash-lid {
  width: 62%;
  height: 10%;
  position: absolute;
  left: 50%;
  margin-left: -31%;
  top: 10.5%;
  background-color: #000;
  border-top-left-radius: 80%;
  border-top-right-radius: 80%;
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

.icon-trash .trash-lid:after {
  content: "";
  width: 26%;
  height: 100%;
  position: absolute;
  left: 50%;
  margin-left: -13%;
  margin-top: -10%;
  background-color: inherit;
  border-top-left-radius: 30%;
  border-top-right-radius: 30%;
  -webkit-transform: rotate(-1deg);
  -moz-transform: rotate(-1deg);
  -ms-transform: rotate(-1deg);
  transform: rotate(-1deg);
}

.icon-trash .trash-container {
  width: 56%;
  height: 65%;
  position: absolute;
  left: 50%;
  margin-left: -28%;
  bottom: 10%;
  background-color: #000;
  border-bottom-left-radius: 15%;
  border-bottom-right-radius: 15%;
}

.icon-trash .trash-line-1 {
  width: 4%;
  height: 50%;
  position: absolute;
  left: 38%;
  margin-left: -2%;
  bottom: 17%;
  background-color: #fff;
}

.icon-trash .trash-line-2 {
  width: 4%;
  height: 50%;
  position: absolute;
  left: 50%;
  margin-left: -2%;
  bottom: 17%;
  background-color: #fff;
}

.icon-trash .trash-line-3 {
  width: 4%;
  height: 50%;
  position: absolute;
  left: 62%;
  margin-left: -2%;
  bottom: 17%;
  background-color: #fff;
}